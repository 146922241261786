.la-angle-up:before {
	content: "\f106";
}

*,
:after,
:before {
	box-sizing: border-box;
}

.auth-page {
	padding-top: 10vh;
	height: 100vh;
}

.style-input {
	font-size: "12px";
	color: "#313140";
	background: "#fff";
	margin-top: "1px";
	margin-bottom: "10px";
	text-align: "left";
	height: "29px";
	padding: "10px";
}

$myColor: black;
$background: rgb(250, 150, 150);

#Recordtimestamp {
	font-size: "11px";
	padding-top: "10px";
	margin-bottom: "1px";
	font-weight: "bold";
	color: $myColor;
}

#BtnActions {
	transition: 0.3s;
}

#IconPadrao {
	border: none;
	font-size: 11px;
	width: 25px;
	left: -1;
	height: 21px;
}

$ColorPdf: red;

#IconHover:hover {
	color: #000000;
}

#IconSave:hover {
	color: #7bfa3e;
}

#IconRemove:hover {
	color: #e31c05;
}

.IconRemove:hover {
	color: #e31c05;
}

#IconColor:hover {
	color: #1e90ff;
}

.IconColor:hover {
	color: #1e90ff;
}

#IconPdf:hover {
	color: #e31c05;
}

.IconPdf:hover {
	color: #e31c05;
}

#IconXML:hover {
	color: #1e90ff;
}

#IconPlus:hover {
	color: #10e024;
}

.IconPlus:hover {
	color: #10e024;
}

#RadioGroup {
	background-color: #e31c05;
}

#RadioButton {
	width: 10px;
	height: 20px;
	background-color: white;
	border-color: white;
	border: none;
	margin-top: 2px;
}

#BtnAcao {
	margin-left: 20px;
}

#BtnAcao:hover {
	color: black;
	transition: 0.2s;
}
