@import '../../styles/app';

.title {
  margin-top: 0;
  color: $widget-title-color;

  @include clearfix();
}

:global .widget.collapsed {
  min-height: unset;
}

.widget {
  display: block;
  position: relative;
  margin-bottom: $grid-gutter-width;
  padding: $widget-padding-vertical $widget-padding-horizontal;
  background: $widget-bg-color;
  border-radius: $widget-border-radius;
  box-shadow: var(--widget-shadow);
  min-height: 150px;

  > header {
    margin: (-$widget-padding-vertical) (-$widget-padding-horizontal);
    padding: $widget-padding-vertical $widget-padding-horizontal;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  :global {
    .loader {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .spinner {
        position: absolute;
        top: 50%;
        width: 100%; //ie fix
        margin-top: -10px;
        font-size: 20px;
        text-align: center;
      }
    }

    .widget-body.p-0 {
      margin: $widget-padding-vertical (-$widget-padding-horizontal) (-$widget-padding-vertical);

      + footer {
        margin-top: $widget-padding-vertical;
      }
    }
  }

  &:global.bg-transparent {
    box-shadow: none;
  }
}

.widgetBody {
  @include clearfix();

  > footer {
    margin: $spacer/2 (-$widget-padding-horizontal) (-$widget-padding-vertical);
    padding: 10px 20px;
  }
}

.widgetControls + .widgetBody {
  margin-top: $widget-padding-vertical;
}

.widgetControls,
:global(.widget-controls) {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 14px;
  font-size: $font-size-sm;

  button {
    padding: 1px 4px;
    border-radius: 4px;
    color: rgba($black, 0.4);
    background: transparent;
    border: none;

    @include transition(color 0.15s ease-in-out);

    &:hover {
      color: rgba($black, 0.1);
      text-decoration: none;
    }

    &:active,
    &:focus {
      outline: none;
    }

    :global {
      .la {
        position: relative;
        top: 2px;
      }

      .glyphicon {
        font-size: 0.7rem;
      }
    }
  }
}

.inverse {
  top: 2px;
  position: relative;
  margin-left: 3px;

  :global {
    .glyphicon {
      vertical-align: baseline;
    }
  }
}

:global {
  .widget-image {
    position: relative;
    overflow: hidden;
    margin: (-$widget-padding-vertical) (-$widget-padding-horizontal);
    border-radius: $border-radius;

    > img {
      max-width: 100%;
      border-radius: $border-radius $border-radius 0 0;
      transition: transform 0.15s ease;
    }

    &:hover > img {
      transform: scale(1.1, 1.1);
    }

    .title {
      position: absolute;
      top: 0;
      left: 0;
      margin: 20px;
    }

    .info {
      position: absolute;
      top: 0;
      right: 0;
      margin: 20px;
    }
  }

  .widget-footer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .widget-sm {
    height: 230px;
  }

  .widget-md {
    height: 373px;
  }

  .widget-padding-md {
    padding: $widget-padding-vertical $widget-padding-horizontal;
  }

  .widget-padding-lg {
    padding: $widget-padding-vertical*2 $widget-padding-horizontal*2;
  }

  .widget-body-container {
    position: relative;
    height: 100%;
  }

  .widget-top-overflow,
  .widget-middle-overflow {
    position: relative;
    margin: 0 (-$widget-padding-horizontal);

    > img {
      max-width: 100%;
    }
  }

  .widget-top-overflow {
    margin-top: (-$widget-padding-vertical);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;

    > img {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    > .btn-toolbar {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      margin-right: $widget-padding-horizontal;

      @include media-breakpoint-up(md) {
        top: auto;
        bottom: 0;
      }
    }
  }

  .widget-icon {
    opacity: 0.5;
    font-size: 42px;
    height: 60px;
    line-height: 45px;
    display: inline-block;
  }
}

.widgetLoader {
  position: absolute;
  top: 0;
  left: 0;
}
