@import '../../styles/app';

.notifications {
  @include media-breakpoint-up(md) {
    width: 333px;
  }

  height: 100%;
  border: none;
}

.cardHeader {
  border-radius: 0;
}

.cardFooter {
  padding-top: 14px;
  padding-bottom: 14px;
}

.btnNotificationsReload {
  color: $navbar-link-color;
  outline: none;

  i::before {
    top: 2px;
  }
}
