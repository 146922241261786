.headerBreadcrumb {
	border-bottom: 1px solid #dedede;
	padding: 5px;
	background: #fff;
	margin-bottom: 15px;
	margin-top: -30px;

	.breadcrumb {
		padding: 5px;
	}
}

.iconAction {
	font-size: 11px !important;
	width: 20px !important;
	height: 20px !important;
}

.iconActionSpan {
	position: relative !important;
	text-align: center !important;
	left: -5px !important;
	top: 1px !important;
	font-size: 13px !important;
}

.label {
	font-size: 11px;
	padding-top: 10px;
	margin-bottom: 1px;
	font-weight: bold;
	color: #263a4a;
}

.react-resizable {
	position: relative;
	background-clip: padding-box;
}

.react-resizable-handle {
	position: absolute;
	width: 10px;
	height: 100%;
	bottom: 0;
	right: -5px;
	cursor: col-resize;
	z-index: 1;
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
	width: 50px !important;
	min-width: 10px !important;
	text-align: center;
}

.ant-pro-table-list-toolbar-container {
	padding: 7px 0 !important;
}

.ant-pro-table-list-toolbar {
	padding-bottom: 5px;
	padding-top: 5px;
	padding-right: 5px;
}

.ant-pro-table-column-setting-list-item-option {
	display: none;
}
.ant-pro-table-alert {
	margin-bottom: 5px;
}

#ant-table-content {
	font-size: 11px;
	font-style: normal;
	font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
}

#ant-table-content td {
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

.row-dragging {
	background: #fafafa;
	border: 1px solid #ccc;
}

.row-dragging td {
	padding: 16px;
}

.row-dragging .drag-visible {
	visibility: visible;
}

.grecaptcha-badge {
    visibility: hidden !important;
}