@import '../../../styles/app';

.list-group-sortable {
  > .list-group-item {
    margin-bottom: 0;
    border-radius: $border-radius;

    + .list-group-item {
      margin-top: $spacer;
    }
  }

  > .list-group-item-placeholder {
    border: 1px dashed $gray-300;
    background-color: $gray-600;
  }

  &:last-of-type > .list-group-item:last-child {
    border-bottom: 1px solid $list-group-border-color;
  }
}

.nestable {
  min-height: 600px;
}
