.react-resizable {
	position: relative;
	background-clip: padding-box;
}

.react-resizable-handle {
	position: absolute;
	width: 10px;
	height: 100%;
	bottom: 0;
	right: -5px;
	cursor: col-resize;
	z-index: 1;
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
	width: 10px !important;
	min-width: 10px !important;
	text-align: center;
}

#ant-table-content {
	font-size: 11px;
	font-style: normal;
	font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
}

#ant-table-content td {
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

/*
#ant-table-content tr:nth-child(even) td{
    background: #FFF
}

    #ant-table-content tr:nth-child(odd) td {
        background: #f9fbfd
    }


*/
