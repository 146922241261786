/*
  Flaticon icon font: Flaticon
  Creation date: 22/06/2016 15:41
*/

@font-face {
	font-family: "Flaticon";
	src: url("../fonts/flaticon/Flaticon.eot");
	src: url("../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"), url("../fonts/flaticon/Flaticon.woff") format("woff"), url("../fonts/flaticon/Flaticon.ttf") format("truetype"),
		url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/flaticon/Flaticon.eot#Flaticon") format("svg");
	}
}

.fi::before {
	display: inline-block;
	font-family: "Flaticon", Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

.flaticon-add::before {
	content: "\f100";
}

.flaticon-add-1::before {
	content: "\f101";
}

.flaticon-add-2::before {
	content: "\f102";
}

.flaticon-add-3::before {
	content: "\f103";
}

.flaticon-agenda::before {
	content: "\f104";
}

.flaticon-alarm::before {
	content: "\f105";
}

.flaticon-alarm-1::before {
	content: "\f106";
}

.flaticon-alarm-clock::before {
	content: "\f107";
}

.flaticon-alarm-clock-1::before {
	content: "\f108";
}

.flaticon-albums::before {
	content: "\f109";
}

.flaticon-app::before {
	content: "\f10a";
}

.flaticon-archive::before {
	content: "\f10b";
}

.flaticon-archive-1::before {
	content: "\f10c";
}

.flaticon-archive-2::before {
	content: "\f10d";
}

.flaticon-archive-3::before {
	content: "\f10e";
}

.flaticon-attachment::before {
	content: "\f10f";
}

.flaticon-back::before {
	content: "\f110";
}

.flaticon-battery::before {
	content: "\f111";
}

.flaticon-battery-1::before {
	content: "\f112";
}

.flaticon-battery-2::before {
	content: "\f113";
}

.flaticon-battery-3::before {
	content: "\f114";
}

.flaticon-battery-4::before {
	content: "\f115";
}

.flaticon-battery-5::before {
	content: "\f116";
}

.flaticon-battery-6::before {
	content: "\f117";
}

.flaticon-battery-7::before {
	content: "\f118";
}

.flaticon-battery-8::before {
	content: "\f119";
}

.flaticon-battery-9::before {
	content: "\f11a";
}

.flaticon-binoculars::before {
	content: "\f11b";
}

.flaticon-blueprint::before {
	content: "\f11c";
}

.flaticon-bluetooth::before {
	content: "\f11d";
}

.flaticon-bluetooth-1::before {
	content: "\f11e";
}

.flaticon-bookmark::before {
	content: "\f11f";
}

.flaticon-bookmark-1::before {
	content: "\f120";
}

.flaticon-briefcase::before {
	content: "\f121";
}

.flaticon-broken-link::before {
	content: "\f122";
}

.flaticon-calculator::before {
	content: "\f123";
}

.flaticon-calculator-1::before {
	content: "\f124";
}

.flaticon-calendar::before {
	content: "\f125";
}

.flaticon-calendar-1::before {
	content: "\f126";
}

.flaticon-calendar-2::before {
	content: "\f127";
}

.flaticon-calendar-3::before {
	content: "\f128";
}

.flaticon-calendar-4::before {
	content: "\f129";
}

.flaticon-calendar-5::before {
	content: "\f12a";
}

.flaticon-calendar-6::before {
	content: "\f12b";
}

.flaticon-calendar-7::before {
	content: "\f12c";
}

.flaticon-checked::before {
	content: "\f12d";
}

.flaticon-checked-1::before {
	content: "\f12e";
}

.flaticon-clock::before {
	content: "\f12f";
}

.flaticon-clock-1::before {
	content: "\f130";
}

.flaticon-close::before {
	content: "\f131";
}

.flaticon-cloud::before {
	content: "\f132";
}

.flaticon-cloud-computing::before {
	content: "\f133";
}

.flaticon-cloud-computing-1::before {
	content: "\f134";
}

.flaticon-cloud-computing-2::before {
	content: "\f135";
}

.flaticon-cloud-computing-3::before {
	content: "\f136";
}

.flaticon-cloud-computing-4::before {
	content: "\f137";
}

.flaticon-cloud-computing-5::before {
	content: "\f138";
}

.flaticon-command::before {
	content: "\f139";
}

.flaticon-compact-disc::before {
	content: "\f13a";
}

.flaticon-compact-disc-1::before {
	content: "\f13b";
}

.flaticon-compact-disc-2::before {
	content: "\f13c";
}

.flaticon-compass::before {
	content: "\f13d";
}

.flaticon-compose::before {
	content: "\f13e";
}

.flaticon-controls::before {
	content: "\f13f";
}

.flaticon-controls-1::before {
	content: "\f140";
}

.flaticon-controls-2::before {
	content: "\f141";
}

.flaticon-controls-3::before {
	content: "\f142";
}

.flaticon-controls-4::before {
	content: "\f143";
}

.flaticon-controls-5::before {
	content: "\f144";
}

.flaticon-controls-6::before {
	content: "\f145";
}

.flaticon-controls-7::before {
	content: "\f146";
}

.flaticon-controls-8::before {
	content: "\f147";
}

.flaticon-controls-9::before {
	content: "\f148";
}

.flaticon-database::before {
	content: "\f149";
}

.flaticon-database-1::before {
	content: "\f14a";
}

.flaticon-database-2::before {
	content: "\f14b";
}

.flaticon-database-3::before {
	content: "\f14c";
}

.flaticon-diamond::before {
	content: "\f14d";
}

.flaticon-diploma::before {
	content: "\f14e";
}

.flaticon-dislike::before {
	content: "\f14f";
}

.flaticon-dislike-1::before {
	content: "\f150";
}

.flaticon-divide::before {
	content: "\f151";
}

.flaticon-divide-1::before {
	content: "\f152";
}

.flaticon-division::before {
	content: "\f153";
}

.flaticon-document::before {
	content: "\f154";
}

.flaticon-download::before {
	content: "\f155";
}

.flaticon-edit::before {
	content: "\f156";
}

.flaticon-edit-1::before {
	content: "\f157";
}

.flaticon-eject::before {
	content: "\f158";
}

.flaticon-eject-1::before {
	content: "\f159";
}

.flaticon-equal::before {
	content: "\f15a";
}

.flaticon-equal-1::before {
	content: "\f15b";
}

.flaticon-equal-2::before {
	content: "\f15c";
}

.flaticon-error::before {
	content: "\f15d";
}

.flaticon-exit::before {
	content: "\f15e";
}

.flaticon-exit-1::before {
	content: "\f15f";
}

.flaticon-exit-2::before {
	content: "\f160";
}

.flaticon-eyeglasses::before {
	content: "\f161";
}

.flaticon-fast-forward::before {
	content: "\f162";
}

.flaticon-fast-forward-1::before {
	content: "\f163";
}

.flaticon-fax::before {
	content: "\f164";
}

.flaticon-file::before {
	content: "\f165";
}

.flaticon-file-1::before {
	content: "\f166";
}

.flaticon-file-2::before {
	content: "\f167";
}

.flaticon-film::before {
	content: "\f168";
}

.flaticon-fingerprint::before {
	content: "\f169";
}

.flaticon-flag::before {
	content: "\f16a";
}

.flaticon-flag-1::before {
	content: "\f16b";
}

.flaticon-flag-2::before {
	content: "\f16c";
}

.flaticon-flag-3::before {
	content: "\f16d";
}

.flaticon-flag-4::before {
	content: "\f16e";
}

.flaticon-focus::before {
	content: "\f16f";
}

.flaticon-folder::before {
	content: "\f170";
}

.flaticon-folder-1::before {
	content: "\f171";
}

.flaticon-folder-10::before {
	content: "\f172";
}

.flaticon-folder-11::before {
	content: "\f173";
}

.flaticon-folder-12::before {
	content: "\f174";
}

.flaticon-folder-13::before {
	content: "\f175";
}

.flaticon-folder-14::before {
	content: "\f176";
}

.flaticon-folder-15::before {
	content: "\f177";
}

.flaticon-folder-16::before {
	content: "\f178";
}

.flaticon-folder-17::before {
	content: "\f179";
}

.flaticon-folder-18::before {
	content: "\f17a";
}

.flaticon-folder-19::before {
	content: "\f17b";
}

.flaticon-folder-2::before {
	content: "\f17c";
}

.flaticon-folder-3::before {
	content: "\f17d";
}

.flaticon-folder-4::before {
	content: "\f17e";
}

.flaticon-folder-5::before {
	content: "\f17f";
}

.flaticon-folder-6::before {
	content: "\f180";
}

.flaticon-folder-7::before {
	content: "\f181";
}

.flaticon-folder-8::before {
	content: "\f182";
}

.flaticon-folder-9::before {
	content: "\f183";
}

.flaticon-forbidden::before {
	content: "\f184";
}

.flaticon-funnel::before {
	content: "\f185";
}

.flaticon-garbage::before {
	content: "\f186";
}

.flaticon-garbage-1::before {
	content: "\f187";
}

.flaticon-garbage-2::before {
	content: "\f188";
}

.flaticon-gift::before {
	content: "\f189";
}

.flaticon-help::before {
	content: "\f18a";
}

.flaticon-hide::before {
	content: "\f18b";
}

.flaticon-hold::before {
	content: "\f18c";
}

.flaticon-home::before {
	content: "\f18d";
}

.flaticon-home-1::before {
	content: "\f18e";
}

.flaticon-home-2::before {
	content: "\f18f";
}

.flaticon-hourglass::before {
	content: "\f190";
}

.flaticon-hourglass-1::before {
	content: "\f191";
}

.flaticon-hourglass-2::before {
	content: "\f192";
}

.flaticon-hourglass-3::before {
	content: "\f193";
}

.flaticon-house::before {
	content: "\f194";
}

.flaticon-id-card::before {
	content: "\f195";
}

.flaticon-id-card-1::before {
	content: "\f196";
}

.flaticon-id-card-2::before {
	content: "\f197";
}

.flaticon-id-card-3::before {
	content: "\f198";
}

.flaticon-id-card-4::before {
	content: "\f199";
}

.flaticon-id-card-5::before {
	content: "\f19a";
}

.flaticon-idea::before {
	content: "\f19b";
}

.flaticon-incoming::before {
	content: "\f19c";
}

.flaticon-infinity::before {
	content: "\f19d";
}

.flaticon-info::before {
	content: "\f19e";
}

.flaticon-internet::before {
	content: "\f19f";
}

.flaticon-key::before {
	content: "\f1a0";
}

.flaticon-lamp::before {
	content: "\f1a1";
}

.flaticon-layers::before {
	content: "\f1a2";
}

.flaticon-layers-1::before {
	content: "\f1a3";
}

.flaticon-like::before {
	content: "\f1a4";
}

.flaticon-like-1::before {
	content: "\f1a5";
}

.flaticon-like-2::before {
	content: "\f1a6";
}

.flaticon-link::before {
	content: "\f1a7";
}

.flaticon-list::before {
	content: "\f1a8";
}

.flaticon-list-1::before {
	content: "\f1a9";
}

.flaticon-lock::before {
	content: "\f1aa";
}

.flaticon-lock-1::before {
	content: "\f1ab";
}

.flaticon-locked::before {
	content: "\f1ac";
}

.flaticon-locked-1::before {
	content: "\f1ad";
}

.flaticon-locked-2::before {
	content: "\f1ae";
}

.flaticon-locked-3::before {
	content: "\f1af";
}

.flaticon-locked-4::before {
	content: "\f1b0";
}

.flaticon-locked-5::before {
	content: "\f1b1";
}

.flaticon-locked-6::before {
	content: "\f1b2";
}

.flaticon-login::before {
	content: "\f1b3";
}

.flaticon-magic-wand::before {
	content: "\f1b4";
}

.flaticon-magnet::before {
	content: "\f1b5";
}

.flaticon-magnet-1::before {
	content: "\f1b6";
}

.flaticon-magnet-2::before {
	content: "\f1b7";
}

.flaticon-map::before {
	content: "\f1b8";
}

.flaticon-map-1::before {
	content: "\f1b9";
}

.flaticon-map-2::before {
	content: "\f1ba";
}

.flaticon-map-location::before {
	content: "\f1bb";
}

.flaticon-megaphone::before {
	content: "\f1bc";
}

.flaticon-megaphone-1::before {
	content: "\f1bd";
}

.flaticon-menu::before {
	content: "\f1be";
}

.flaticon-menu-1::before {
	content: "\f1bf";
}

.flaticon-menu-2::before {
	content: "\f1c0";
}

.flaticon-menu-3::before {
	content: "\f1c1";
}

.flaticon-menu-4::before {
	content: "\f1c2";
}

.flaticon-microphone::before {
	content: "\f1c3";
}

.flaticon-microphone-1::before {
	content: "\f1c4";
}

.flaticon-minus::before {
	content: "\f1c5";
}

.flaticon-minus-1::before {
	content: "\f1c6";
}

.flaticon-more::before {
	content: "\f1c7";
}

.flaticon-more-1::before {
	content: "\f1c8";
}

.flaticon-more-2::before {
	content: "\f1c9";
}

.flaticon-multiply::before {
	content: "\f1ca";
}

.flaticon-multiply-1::before {
	content: "\f1cb";
}

.flaticon-music-player::before {
	content: "\f1cc";
}

.flaticon-music-player-1::before {
	content: "\f1cd";
}

.flaticon-music-player-2::before {
	content: "\f1ce";
}

.flaticon-music-player-3::before {
	content: "\f1cf";
}

.flaticon-mute::before {
	content: "\f1d0";
}

.flaticon-muted::before {
	content: "\f1d1";
}

.flaticon-navigation::before {
	content: "\f1d2";
}

.flaticon-navigation-1::before {
	content: "\f1d3";
}

.flaticon-network::before {
	content: "\f1d4";
}

.flaticon-newspaper::before {
	content: "\f1d5";
}

.flaticon-next::before {
	content: "\f1d6";
}

.flaticon-note::before {
	content: "\f1d7";
}

.flaticon-notebook::before {
	content: "\f1d8";
}

.flaticon-notebook-1::before {
	content: "\f1d9";
}

.flaticon-notebook-2::before {
	content: "\f1da";
}

.flaticon-notebook-3::before {
	content: "\f1db";
}

.flaticon-notebook-4::before {
	content: "\f1dc";
}

.flaticon-notebook-5::before {
	content: "\f1dd";
}

.flaticon-notepad::before {
	content: "\f1de";
}

.flaticon-notepad-1::before {
	content: "\f1df";
}

.flaticon-notepad-2::before {
	content: "\f1e0";
}

.flaticon-notification::before {
	content: "\f1e1";
}

.flaticon-paper-plane::before {
	content: "\f1e2";
}

.flaticon-paper-plane-1::before {
	content: "\f1e3";
}

.flaticon-pause::before {
	content: "\f1e4";
}

.flaticon-pause-1::before {
	content: "\f1e5";
}

.flaticon-percent::before {
	content: "\f1e6";
}

.flaticon-percent-1::before {
	content: "\f1e7";
}

.flaticon-perspective::before {
	content: "\f1e8";
}

.flaticon-photo-camera::before {
	content: "\f1e9";
}

.flaticon-photo-camera-1::before {
	content: "\f1ea";
}

.flaticon-photos::before {
	content: "\f1eb";
}

.flaticon-picture::before {
	content: "\f1ec";
}

.flaticon-picture-1::before {
	content: "\f1ed";
}

.flaticon-picture-2::before {
	content: "\f1ee";
}

.flaticon-pin::before {
	content: "\f1ef";
}

.flaticon-placeholder::before {
	content: "\f1f0";
}

.flaticon-placeholder-1::before {
	content: "\f1f1";
}

.flaticon-placeholder-2::before {
	content: "\f1f2";
}

.flaticon-placeholder-3::before {
	content: "\f1f3";
}

.flaticon-placeholders::before {
	content: "\f1f4";
}

.flaticon-play-button::before {
	content: "\f1f5";
}

.flaticon-play-button-1::before {
	content: "\f1f6";
}

.flaticon-plus::before {
	content: "\f1f7";
}

.flaticon-power::before {
	content: "\f1f8";
}

.flaticon-previous::before {
	content: "\f1f9";
}

.flaticon-price-tag::before {
	content: "\f1fa";
}

.flaticon-print::before {
	content: "\f1fb";
}

.flaticon-push-pin::before {
	content: "\f1fc";
}

.flaticon-radar::before {
	content: "\f1fd";
}

.flaticon-reading::before {
	content: "\f1fe";
}

.flaticon-record::before {
	content: "\f1ff";
}

.flaticon-repeat::before {
	content: "\f200";
}

.flaticon-repeat-1::before {
	content: "\f201";
}

.flaticon-restart::before {
	content: "\f202";
}

.flaticon-resume::before {
	content: "\f203";
}

.flaticon-rewind::before {
	content: "\f204";
}

.flaticon-rewind-1::before {
	content: "\f205";
}

.flaticon-route::before {
	content: "\f206";
}

.flaticon-save::before {
	content: "\f207";
}

.flaticon-search::before {
	content: "\f208";
}

.flaticon-search-1::before {
	content: "\f209";
}

.flaticon-send::before {
	content: "\f20a";
}

.flaticon-server::before {
	content: "\f20b";
}

.flaticon-server-1::before {
	content: "\f20c";
}

.flaticon-server-2::before {
	content: "\f20d";
}

.flaticon-server-3::before {
	content: "\f20e";
}

.flaticon-settings::before {
	content: "\f20f";
}

.flaticon-settings-1::before {
	content: "\f210";
}

.flaticon-settings-2::before {
	content: "\f211";
}

.flaticon-settings-3::before {
	content: "\f212";
}

.flaticon-settings-4::before {
	content: "\f213";
}

.flaticon-settings-5::before {
	content: "\f214";
}

.flaticon-settings-6::before {
	content: "\f215";
}

.flaticon-settings-7::before {
	content: "\f216";
}

.flaticon-settings-8::before {
	content: "\f217";
}

.flaticon-settings-9::before {
	content: "\f218";
}

.flaticon-share::before {
	content: "\f219";
}

.flaticon-share-1::before {
	content: "\f21a";
}

.flaticon-share-2::before {
	content: "\f21b";
}

.flaticon-shuffle::before {
	content: "\f21c";
}

.flaticon-shuffle-1::before {
	content: "\f21d";
}

.flaticon-shutdown::before {
	content: "\f21e";
}

.flaticon-sign::before {
	content: "\f21f";
}

.flaticon-sign-1::before {
	content: "\f220";
}

.flaticon-skip::before {
	content: "\f221";
}

.flaticon-smartphone::before {
	content: "\f222";
}

.flaticon-smartphone-1::before {
	content: "\f223";
}

.flaticon-smartphone-10::before {
	content: "\f224";
}

.flaticon-smartphone-11::before {
	content: "\f225";
}

.flaticon-smartphone-2::before {
	content: "\f226";
}

.flaticon-smartphone-3::before {
	content: "\f227";
}

.flaticon-smartphone-4::before {
	content: "\f228";
}

.flaticon-smartphone-5::before {
	content: "\f229";
}

.flaticon-smartphone-6::before {
	content: "\f22a";
}

.flaticon-smartphone-7::before {
	content: "\f22b";
}

.flaticon-smartphone-8::before {
	content: "\f22c";
}

.flaticon-smartphone-9::before {
	content: "\f22d";
}

.flaticon-speaker::before {
	content: "\f22e";
}

.flaticon-speaker-1::before {
	content: "\f22f";
}

.flaticon-speaker-2::before {
	content: "\f230";
}

.flaticon-speaker-3::before {
	content: "\f231";
}

.flaticon-speaker-4::before {
	content: "\f232";
}

.flaticon-speaker-5::before {
	content: "\f233";
}

.flaticon-speaker-6::before {
	content: "\f234";
}

.flaticon-speaker-7::before {
	content: "\f235";
}

.flaticon-speaker-8::before {
	content: "\f236";
}

.flaticon-spotlight::before {
	content: "\f237";
}

.flaticon-star::before {
	content: "\f238";
}

.flaticon-star-1::before {
	content: "\f239";
}

.flaticon-stop::before {
	content: "\f23a";
}

.flaticon-stop-1::before {
	content: "\f23b";
}

.flaticon-stopwatch::before {
	content: "\f23c";
}

.flaticon-stopwatch-1::before {
	content: "\f23d";
}

.flaticon-stopwatch-2::before {
	content: "\f23e";
}

.flaticon-stopwatch-3::before {
	content: "\f23f";
}

.flaticon-stopwatch-4::before {
	content: "\f240";
}

.flaticon-street::before {
	content: "\f241";
}

.flaticon-street-1::before {
	content: "\f242";
}

.flaticon-substract::before {
	content: "\f243";
}

.flaticon-substract-1::before {
	content: "\f244";
}

.flaticon-success::before {
	content: "\f245";
}

.flaticon-switch::before {
	content: "\f246";
}

.flaticon-switch-1::before {
	content: "\f247";
}

.flaticon-switch-2::before {
	content: "\f248";
}

.flaticon-switch-3::before {
	content: "\f249";
}

.flaticon-switch-4::before {
	content: "\f24a";
}

.flaticon-switch-5::before {
	content: "\f24b";
}

.flaticon-switch-6::before {
	content: "\f24c";
}

.flaticon-switch-7::before {
	content: "\f24d";
}

.flaticon-tabs::before {
	content: "\f24e";
}

.flaticon-tabs-1::before {
	content: "\f24f";
}

.flaticon-target::before {
	content: "\f250";
}

.flaticon-television::before {
	content: "\f251";
}

.flaticon-television-1::before {
	content: "\f252";
}

.flaticon-time::before {
	content: "\f253";
}

.flaticon-trash::before {
	content: "\f254";
}

.flaticon-umbrella::before {
	content: "\f255";
}

.flaticon-unlink::before {
	content: "\f256";
}

.flaticon-unlocked::before {
	content: "\f257";
}

.flaticon-unlocked-1::before {
	content: "\f258";
}

.flaticon-unlocked-2::before {
	content: "\f259";
}

.flaticon-upload::before {
	content: "\f25a";
}

.flaticon-user::before {
	content: "\f25b";
}

.flaticon-user-1::before {
	content: "\f25c";
}

.flaticon-user-2::before {
	content: "\f25d";
}

.flaticon-user-3::before {
	content: "\f25e";
}

.flaticon-user-4::before {
	content: "\f25f";
}

.flaticon-user-5::before {
	content: "\f260";
}

.flaticon-user-6::before {
	content: "\f261";
}

.flaticon-user-7::before {
	content: "\f262";
}

.flaticon-users::before {
	content: "\f263";
}

.flaticon-users-1::before {
	content: "\f264";
}

.flaticon-video-camera::before {
	content: "\f265";
}

.flaticon-video-camera-1::before {
	content: "\f266";
}

.flaticon-video-player::before {
	content: "\f267";
}

.flaticon-video-player-1::before {
	content: "\f268";
}

.flaticon-video-player-2::before {
	content: "\f269";
}

.flaticon-view::before {
	content: "\f26a";
}

.flaticon-view-1::before {
	content: "\f26b";
}

.flaticon-view-2::before {
	content: "\f26c";
}

.flaticon-volume-control::before {
	content: "\f26d";
}

.flaticon-volume-control-1::before {
	content: "\f26e";
}

.flaticon-warning::before {
	content: "\f26f";
}

.flaticon-wifi::before {
	content: "\f270";
}

.flaticon-wifi-1::before {
	content: "\f271";
}

.flaticon-windows::before {
	content: "\f272";
}

.flaticon-windows-1::before {
	content: "\f273";
}

.flaticon-windows-2::before {
	content: "\f274";
}

.flaticon-windows-3::before {
	content: "\f275";
}

.flaticon-windows-4::before {
	content: "\f276";
}

.flaticon-wireless-internet::before {
	content: "\f277";
}

.flaticon-worldwide::before {
	content: "\f278";
}

.flaticon-worldwide-1::before {
	content: "\f279";
}

.flaticon-zoom-in::before {
	content: "\f27a";
}

.flaticon-zoom-out::before {
	content: "\f27b";
}

$font-Flaticon-add: "\f100";
$font-Flaticon-add-1: "\f101";
$font-Flaticon-add-2: "\f102";
$font-Flaticon-add-3: "\f103";
$font-Flaticon-agenda: "\f104";
$font-Flaticon-alarm: "\f105";
$font-Flaticon-alarm-1: "\f106";
$font-Flaticon-alarm-clock: "\f107";
$font-Flaticon-alarm-clock-1: "\f108";
$font-Flaticon-albums: "\f109";
$font-Flaticon-app: "\f10a";
$font-Flaticon-archive: "\f10b";
$font-Flaticon-archive-1: "\f10c";
$font-Flaticon-archive-2: "\f10d";
$font-Flaticon-archive-3: "\f10e";
$font-Flaticon-attachment: "\f10f";
$font-Flaticon-back: "\f110";
$font-Flaticon-battery: "\f111";
$font-Flaticon-battery-1: "\f112";
$font-Flaticon-battery-2: "\f113";
$font-Flaticon-battery-3: "\f114";
$font-Flaticon-battery-4: "\f115";
$font-Flaticon-battery-5: "\f116";
$font-Flaticon-battery-6: "\f117";
$font-Flaticon-battery-7: "\f118";
$font-Flaticon-battery-8: "\f119";
$font-Flaticon-battery-9: "\f11a";
$font-Flaticon-binoculars: "\f11b";
$font-Flaticon-blueprint: "\f11c";
$font-Flaticon-bluetooth: "\f11d";
$font-Flaticon-bluetooth-1: "\f11e";
$font-Flaticon-bookmark: "\f11f";
$font-Flaticon-bookmark-1: "\f120";
$font-Flaticon-briefcase: "\f121";
$font-Flaticon-broken-link: "\f122";
$font-Flaticon-calculator: "\f123";
$font-Flaticon-calculator-1: "\f124";
$font-Flaticon-calendar: "\f125";
$font-Flaticon-calendar-1: "\f126";
$font-Flaticon-calendar-2: "\f127";
$font-Flaticon-calendar-3: "\f128";
$font-Flaticon-calendar-4: "\f129";
$font-Flaticon-calendar-5: "\f12a";
$font-Flaticon-calendar-6: "\f12b";
$font-Flaticon-calendar-7: "\f12c";
$font-Flaticon-checked: "\f12d";
$font-Flaticon-checked-1: "\f12e";
$font-Flaticon-clock: "\f12f";
$font-Flaticon-clock-1: "\f130";
$font-Flaticon-close: "\f131";
$font-Flaticon-cloud: "\f132";
$font-Flaticon-cloud-computing: "\f133";
$font-Flaticon-cloud-computing-1: "\f134";
$font-Flaticon-cloud-computing-2: "\f135";
$font-Flaticon-cloud-computing-3: "\f136";
$font-Flaticon-cloud-computing-4: "\f137";
$font-Flaticon-cloud-computing-5: "\f138";
$font-Flaticon-command: "\f139";
$font-Flaticon-compact-disc: "\f13a";
$font-Flaticon-compact-disc-1: "\f13b";
$font-Flaticon-compact-disc-2: "\f13c";
$font-Flaticon-compass: "\f13d";
$font-Flaticon-compose: "\f13e";
$font-Flaticon-controls: "\f13f";
$font-Flaticon-controls-1: "\f140";
$font-Flaticon-controls-2: "\f141";
$font-Flaticon-controls-3: "\f142";
$font-Flaticon-controls-4: "\f143";
$font-Flaticon-controls-5: "\f144";
$font-Flaticon-controls-6: "\f145";
$font-Flaticon-controls-7: "\f146";
$font-Flaticon-controls-8: "\f147";
$font-Flaticon-controls-9: "\f148";
$font-Flaticon-database: "\f149";
$font-Flaticon-database-1: "\f14a";
$font-Flaticon-database-2: "\f14b";
$font-Flaticon-database-3: "\f14c";
$font-Flaticon-diamond: "\f14d";
$font-Flaticon-diploma: "\f14e";
$font-Flaticon-dislike: "\f14f";
$font-Flaticon-dislike-1: "\f150";
$font-Flaticon-divide: "\f151";
$font-Flaticon-divide-1: "\f152";
$font-Flaticon-division: "\f153";
$font-Flaticon-document: "\f154";
$font-Flaticon-download: "\f155";
$font-Flaticon-edit: "\f156";
$font-Flaticon-edit-1: "\f157";
$font-Flaticon-eject: "\f158";
$font-Flaticon-eject-1: "\f159";
$font-Flaticon-equal: "\f15a";
$font-Flaticon-equal-1: "\f15b";
$font-Flaticon-equal-2: "\f15c";
$font-Flaticon-error: "\f15d";
$font-Flaticon-exit: "\f15e";
$font-Flaticon-exit-1: "\f15f";
$font-Flaticon-exit-2: "\f160";
$font-Flaticon-eyeglasses: "\f161";
$font-Flaticon-fast-forward: "\f162";
$font-Flaticon-fast-forward-1: "\f163";
$font-Flaticon-fax: "\f164";
$font-Flaticon-file: "\f165";
$font-Flaticon-file-1: "\f166";
$font-Flaticon-file-2: "\f167";
$font-Flaticon-film: "\f168";
$font-Flaticon-fingerprint: "\f169";
$font-Flaticon-flag: "\f16a";
$font-Flaticon-flag-1: "\f16b";
$font-Flaticon-flag-2: "\f16c";
$font-Flaticon-flag-3: "\f16d";
$font-Flaticon-flag-4: "\f16e";
$font-Flaticon-focus: "\f16f";
$font-Flaticon-folder: "\f170";
$font-Flaticon-folder-1: "\f171";
$font-Flaticon-folder-10: "\f172";
$font-Flaticon-folder-11: "\f173";
$font-Flaticon-folder-12: "\f174";
$font-Flaticon-folder-13: "\f175";
$font-Flaticon-folder-14: "\f176";
$font-Flaticon-folder-15: "\f177";
$font-Flaticon-folder-16: "\f178";
$font-Flaticon-folder-17: "\f179";
$font-Flaticon-folder-18: "\f17a";
$font-Flaticon-folder-19: "\f17b";
$font-Flaticon-folder-2: "\f17c";
$font-Flaticon-folder-3: "\f17d";
$font-Flaticon-folder-4: "\f17e";
$font-Flaticon-folder-5: "\f17f";
$font-Flaticon-folder-6: "\f180";
$font-Flaticon-folder-7: "\f181";
$font-Flaticon-folder-8: "\f182";
$font-Flaticon-folder-9: "\f183";
$font-Flaticon-forbidden: "\f184";
$font-Flaticon-funnel: "\f185";
$font-Flaticon-garbage: "\f186";
$font-Flaticon-garbage-1: "\f187";
$font-Flaticon-garbage-2: "\f188";
$font-Flaticon-gift: "\f189";
$font-Flaticon-help: "\f18a";
$font-Flaticon-hide: "\f18b";
$font-Flaticon-hold: "\f18c";
$font-Flaticon-home: "\f18d";
$font-Flaticon-home-1: "\f18e";
$font-Flaticon-home-2: "\f18f";
$font-Flaticon-hourglass: "\f190";
$font-Flaticon-hourglass-1: "\f191";
$font-Flaticon-hourglass-2: "\f192";
$font-Flaticon-hourglass-3: "\f193";
$font-Flaticon-house: "\f194";
$font-Flaticon-id-card: "\f195";
$font-Flaticon-id-card-1: "\f196";
$font-Flaticon-id-card-2: "\f197";
$font-Flaticon-id-card-3: "\f198";
$font-Flaticon-id-card-4: "\f199";
$font-Flaticon-id-card-5: "\f19a";
$font-Flaticon-idea: "\f19b";
$font-Flaticon-incoming: "\f19c";
$font-Flaticon-infinity: "\f19d";
$font-Flaticon-info: "\f19e";
$font-Flaticon-internet: "\f19f";
$font-Flaticon-key: "\f1a0";
$font-Flaticon-lamp: "\f1a1";
$font-Flaticon-layers: "\f1a2";
$font-Flaticon-layers-1: "\f1a3";
$font-Flaticon-like: "\f1a4";
$font-Flaticon-like-1: "\f1a5";
$font-Flaticon-like-2: "\f1a6";
$font-Flaticon-link: "\f1a7";
$font-Flaticon-list: "\f1a8";
$font-Flaticon-list-1: "\f1a9";
$font-Flaticon-lock: "\f1aa";
$font-Flaticon-lock-1: "\f1ab";
$font-Flaticon-locked: "\f1ac";
$font-Flaticon-locked-1: "\f1ad";
$font-Flaticon-locked-2: "\f1ae";
$font-Flaticon-locked-3: "\f1af";
$font-Flaticon-locked-4: "\f1b0";
$font-Flaticon-locked-5: "\f1b1";
$font-Flaticon-locked-6: "\f1b2";
$font-Flaticon-login: "\f1b3";
$font-Flaticon-magic-wand: "\f1b4";
$font-Flaticon-magnet: "\f1b5";
$font-Flaticon-magnet-1: "\f1b6";
$font-Flaticon-magnet-2: "\f1b7";
$font-Flaticon-map: "\f1b8";
$font-Flaticon-map-1: "\f1b9";
$font-Flaticon-map-2: "\f1ba";
$font-Flaticon-map-location: "\f1bb";
$font-Flaticon-megaphone: "\f1bc";
$font-Flaticon-megaphone-1: "\f1bd";
$font-Flaticon-menu: "\f1be";
$font-Flaticon-menu-1: "\f1bf";
$font-Flaticon-menu-2: "\f1c0";
$font-Flaticon-menu-3: "\f1c1";
$font-Flaticon-menu-4: "\f1c2";
$font-Flaticon-microphone: "\f1c3";
$font-Flaticon-microphone-1: "\f1c4";
$font-Flaticon-minus: "\f1c5";
$font-Flaticon-minus-1: "\f1c6";
$font-Flaticon-more: "\f1c7";
$font-Flaticon-more-1: "\f1c8";
$font-Flaticon-more-2: "\f1c9";
$font-Flaticon-multiply: "\f1ca";
$font-Flaticon-multiply-1: "\f1cb";
$font-Flaticon-music-player: "\f1cc";
$font-Flaticon-music-player-1: "\f1cd";
$font-Flaticon-music-player-2: "\f1ce";
$font-Flaticon-music-player-3: "\f1cf";
$font-Flaticon-mute: "\f1d0";
$font-Flaticon-muted: "\f1d1";
$font-Flaticon-navigation: "\f1d2";
$font-Flaticon-navigation-1: "\f1d3";
$font-Flaticon-network: "\f1d4";
$font-Flaticon-newspaper: "\f1d5";
$font-Flaticon-next: "\f1d6";
$font-Flaticon-note: "\f1d7";
$font-Flaticon-notebook: "\f1d8";
$font-Flaticon-notebook-1: "\f1d9";
$font-Flaticon-notebook-2: "\f1da";
$font-Flaticon-notebook-3: "\f1db";
$font-Flaticon-notebook-4: "\f1dc";
$font-Flaticon-notebook-5: "\f1dd";
$font-Flaticon-notepad: "\f1de";
$font-Flaticon-notepad-1: "\f1df";
$font-Flaticon-notepad-2: "\f1e0";
$font-Flaticon-notification: "\f1e1";
$font-Flaticon-paper-plane: "\f1e2";
$font-Flaticon-paper-plane-1: "\f1e3";
$font-Flaticon-pause: "\f1e4";
$font-Flaticon-pause-1: "\f1e5";
$font-Flaticon-percent: "\f1e6";
$font-Flaticon-percent-1: "\f1e7";
$font-Flaticon-perspective: "\f1e8";
$font-Flaticon-photo-camera: "\f1e9";
$font-Flaticon-photo-camera-1: "\f1ea";
$font-Flaticon-photos: "\f1eb";
$font-Flaticon-picture: "\f1ec";
$font-Flaticon-picture-1: "\f1ed";
$font-Flaticon-picture-2: "\f1ee";
$font-Flaticon-pin: "\f1ef";
$font-Flaticon-placeholder: "\f1f0";
$font-Flaticon-placeholder-1: "\f1f1";
$font-Flaticon-placeholder-2: "\f1f2";
$font-Flaticon-placeholder-3: "\f1f3";
$font-Flaticon-placeholders: "\f1f4";
$font-Flaticon-play-button: "\f1f5";
$font-Flaticon-play-button-1: "\f1f6";
$font-Flaticon-plus: "\f1f7";
$font-Flaticon-power: "\f1f8";
$font-Flaticon-previous: "\f1f9";
$font-Flaticon-price-tag: "\f1fa";
$font-Flaticon-print: "\f1fb";
$font-Flaticon-push-pin: "\f1fc";
$font-Flaticon-radar: "\f1fd";
$font-Flaticon-reading: "\f1fe";
$font-Flaticon-record: "\f1ff";
$font-Flaticon-repeat: "\f200";
$font-Flaticon-repeat-1: "\f201";
$font-Flaticon-restart: "\f202";
$font-Flaticon-resume: "\f203";
$font-Flaticon-rewind: "\f204";
$font-Flaticon-rewind-1: "\f205";
$font-Flaticon-route: "\f206";
$font-Flaticon-save: "\f207";
$font-Flaticon-search: "\f208";
$font-Flaticon-search-1: "\f209";
$font-Flaticon-send: "\f20a";
$font-Flaticon-server: "\f20b";
$font-Flaticon-server-1: "\f20c";
$font-Flaticon-server-2: "\f20d";
$font-Flaticon-server-3: "\f20e";
$font-Flaticon-settings: "\f20f";
$font-Flaticon-settings-1: "\f210";
$font-Flaticon-settings-2: "\f211";
$font-Flaticon-settings-3: "\f212";
$font-Flaticon-settings-4: "\f213";
$font-Flaticon-settings-5: "\f214";
$font-Flaticon-settings-6: "\f215";
$font-Flaticon-settings-7: "\f216";
$font-Flaticon-settings-8: "\f217";
$font-Flaticon-settings-9: "\f218";
$font-Flaticon-share: "\f219";
$font-Flaticon-share-1: "\f21a";
$font-Flaticon-share-2: "\f21b";
$font-Flaticon-shuffle: "\f21c";
$font-Flaticon-shuffle-1: "\f21d";
$font-Flaticon-shutdown: "\f21e";
$font-Flaticon-sign: "\f21f";
$font-Flaticon-sign-1: "\f220";
$font-Flaticon-skip: "\f221";
$font-Flaticon-smartphone: "\f222";
$font-Flaticon-smartphone-1: "\f223";
$font-Flaticon-smartphone-10: "\f224";
$font-Flaticon-smartphone-11: "\f225";
$font-Flaticon-smartphone-2: "\f226";
$font-Flaticon-smartphone-3: "\f227";
$font-Flaticon-smartphone-4: "\f228";
$font-Flaticon-smartphone-5: "\f229";
$font-Flaticon-smartphone-6: "\f22a";
$font-Flaticon-smartphone-7: "\f22b";
$font-Flaticon-smartphone-8: "\f22c";
$font-Flaticon-smartphone-9: "\f22d";
$font-Flaticon-speaker: "\f22e";
$font-Flaticon-speaker-1: "\f22f";
$font-Flaticon-speaker-2: "\f230";
$font-Flaticon-speaker-3: "\f231";
$font-Flaticon-speaker-4: "\f232";
$font-Flaticon-speaker-5: "\f233";
$font-Flaticon-speaker-6: "\f234";
$font-Flaticon-speaker-7: "\f235";
$font-Flaticon-speaker-8: "\f236";
$font-Flaticon-spotlight: "\f237";
$font-Flaticon-star: "\f238";
$font-Flaticon-star-1: "\f239";
$font-Flaticon-stop: "\f23a";
$font-Flaticon-stop-1: "\f23b";
$font-Flaticon-stopwatch: "\f23c";
$font-Flaticon-stopwatch-1: "\f23d";
$font-Flaticon-stopwatch-2: "\f23e";
$font-Flaticon-stopwatch-3: "\f23f";
$font-Flaticon-stopwatch-4: "\f240";
$font-Flaticon-street: "\f241";
$font-Flaticon-street-1: "\f242";
$font-Flaticon-substract: "\f243";
$font-Flaticon-substract-1: "\f244";
$font-Flaticon-success: "\f245";
$font-Flaticon-switch: "\f246";
$font-Flaticon-switch-1: "\f247";
$font-Flaticon-switch-2: "\f248";
$font-Flaticon-switch-3: "\f249";
$font-Flaticon-switch-4: "\f24a";
$font-Flaticon-switch-5: "\f24b";
$font-Flaticon-switch-6: "\f24c";
$font-Flaticon-switch-7: "\f24d";
$font-Flaticon-tabs: "\f24e";
$font-Flaticon-tabs-1: "\f24f";
$font-Flaticon-target: "\f250";
$font-Flaticon-television: "\f251";
$font-Flaticon-television-1: "\f252";
$font-Flaticon-time: "\f253";
$font-Flaticon-trash: "\f254";
$font-Flaticon-umbrella: "\f255";
$font-Flaticon-unlink: "\f256";
$font-Flaticon-unlocked: "\f257";
$font-Flaticon-unlocked-1: "\f258";
$font-Flaticon-unlocked-2: "\f259";
$font-Flaticon-upload: "\f25a";
$font-Flaticon-user: "\f25b";
$font-Flaticon-user-1: "\f25c";
$font-Flaticon-user-2: "\f25d";
$font-Flaticon-user-3: "\f25e";
$font-Flaticon-user-4: "\f25f";
$font-Flaticon-user-5: "\f260";
$font-Flaticon-user-6: "\f261";
$font-Flaticon-user-7: "\f262";
$font-Flaticon-users: "\f263";
$font-Flaticon-users-1: "\f264";
$font-Flaticon-video-camera: "\f265";
$font-Flaticon-video-camera-1: "\f266";
$font-Flaticon-video-player: "\f267";
$font-Flaticon-video-player-1: "\f268";
$font-Flaticon-video-player-2: "\f269";
$font-Flaticon-view: "\f26a";
$font-Flaticon-view-1: "\f26b";
$font-Flaticon-view-2: "\f26c";
$font-Flaticon-volume-control: "\f26d";
$font-Flaticon-volume-control-1: "\f26e";
$font-Flaticon-warning: "\f26f";
$font-Flaticon-wifi: "\f270";
$font-Flaticon-wifi-1: "\f271";
$font-Flaticon-windows: "\f272";
$font-Flaticon-windows-1: "\f273";
$font-Flaticon-windows-2: "\f274";
$font-Flaticon-windows-3: "\f275";
$font-Flaticon-windows-4: "\f276";
$font-Flaticon-wireless-internet: "\f277";
$font-Flaticon-worldwide: "\f278";
$font-Flaticon-worldwide-1: "\f279";
$font-Flaticon-zoom-in: "\f27a";
$font-Flaticon-zoom-out: "\f27b";
