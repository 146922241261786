
.select {
  font-size: 12px;
}

.input{
    width: 10px;
}






