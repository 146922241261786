@import '../../../../../styles/app';

:global {
  @import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg';
  @import '../../../../../../node_modules/react-select2-wrapper/css/select2';
  @import '../../../../../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';
  @import '../../../../../../node_modules/react-datetime/css/react-datetime';
  @import '../../../../../../node_modules/rc-color-picker/dist/rc-color-picker';
  @import '../../../../../../node_modules/bootstrap-slider/dist/css/bootstrap-slider';
  @import '../../../../../../node_modules/jasny-bootstrap/dist/css/jasny-bootstrap';
  @import '../../../../../../node_modules/react-mde/lib/styles/scss/react-mde-all';
}

.column {
  font-size: 12px;
  vertical-align: middle;
}

.root {
  :global .tile {
    display: inline-block;
  }
}

.galleryControls {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
}

.picture {
  padding: 10px;
  border-radius: $border-radius;


  >a {
    overflow: hidden;
  }

  :global .figure-img {
    width: 100%;
    transition: $transition-base;
  }

  &:hover {
    :global .figure-img {
      transform: scale(1.1, 1.1);
    }
  }
}

.description {
  padding: ($spacer * 0.85) ($spacer * 0.5);
}
