@import '../../../../../styles/app';

:global {
  @import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg';
  @import '../../../../../../node_modules/react-select2-wrapper/css/select2';
  @import '../../../../../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';
  @import '../../../../../../node_modules/react-datetime/css/react-datetime';
  @import '../../../../../../node_modules/rc-color-picker/dist/rc-color-picker';
  @import '../../../../../../node_modules/bootstrap-slider/dist/css/bootstrap-slider';
  @import '../../../../../../node_modules/jasny-bootstrap/dist/css/jasny-bootstrap';
  @import '../../../../../../node_modules/react-mde/lib/styles/scss/react-mde-all';
}

.select {
  font-size: 12px;
}

.column {
  font-size: 12px;
}



